<template>
  <div
    class="page"
    :style="{
      backgroundColor: configValue.backgroundColor,
      backgroundImage: `url(${configValue.backgroundImage})`
    }"
  >
    <div class="pageContainer">
      <div class="logoContainer">
        <img
          v-if="configValue.image"
          :src="configValue.image"
          :style="{
            width: configValue.imageWidth + 'px',
            height: configValue.imageHeight + 'px',
            borderRadius: configValue.imageRadius + 'px'
          }"
        />
        <span
          v-if="configValue.sloganText"
          class="name"
          :style="{
            fontSize: configValue.sloganTextSize + 'px',
            color: configValue.sloganTextColor
          }"
          >{{ configValue.sloganText }}</span
        >
      </div>
      <div class="formCard">
        <div class="tip">用已经登录商城微信手机号验证绑定分销来完成分销注册</div>
        <van-form>
          <van-field
            v-model="form.phone"
            name="手机号"
            type="number"
            placeholder="请输入手机号"
            @input="form.phone = form.phone.replaceAll(' ', '')"
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            type="number"
            v-model="form.code"
            center
            clearable
            @clear="form.code = ''"
            placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button
                size="small"
                type="primary"
                class="btn"
                @click="getCode"
                :disabled="send || form.phone === ''"
              >
                发送验证码
                <van-count-down
                  v-if="send"
                  :time="3 * 60 * 1000"
                  format="ss"
                  @finish="send = false"
                />
              </van-button>
            </template>
          </van-field>
          <div class="invite-member" v-if="inviteMember.avatarUrl">
            <span class="tip">邀请人：</span>
            <img :src="inviteMember.avatarUrl" class="avatar" />
            <span class="name">{{ inviteMember.wxNickname }}</span>
          </div>
          <div style="margin: 16px">
            <van-button
              class="btn"
              round
              block
              :loading="loading"
              type="info"
              @click="submit"
              :disabled="form.code === '' || form.phone === ''"
            >
              提交
            </van-button>
          </div>
        </van-form>
      </div>
      <div class="wxOpenTags"></div>
    </div>
    <van-dialog
      v-model="dialog.show"
      title="提示"
      show-cancel-button
      :showConfirmButton="false"
      :showCancelButton="true"
      cancelButtonText="关闭"
    >
      <div style="text-align: center; padding: 20px">
        <div style="font-size: 15px; color: #999; margin-bottom: 20px">{{ dialog.msg }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Notify, Dialog } from 'vant'
import axios from 'axios'
const baseURL = process.env.VUE_APP_baseUrl
export default {
  layout: 'fullPage',
  data() {
    return {
      loading: false,
      inviteId: this.$route.query.id || 0,
      form: {
        phone: '',
        code: ''
      },
      send: false,
      dialog: {
        show: false,
        msg: ''
      },
      wxOpenTags: '',
      inviteMember: {},
      configValue: {
        backgroundColor: '',
        backgroundImage: '',
        image: '',
        imageWidth: '',
        imageHeight: '',
        imageRadius: '',
        sloganText: '',
        sloganTextSize: '',
        sloganTextColor: '',
        descTextSize: '',
        descTextColor: ''
      }
    }
  },
  created() {
    this.form.phone = this.$route.query.phone
    if (this.$route.query?.tenantId) {
      localStorage.tenantId = this.$route.query.tenantId
    }
    // if (this.$store.getters['user/isLogin']) {
    //   this.$router.replace('/home')
    // }
  },
  mounted() {
    this.getLoginPageConfig()
    this.getInviteMember()
  },
  methods: {
    getLoginPageConfig() {
      this.$http
        .get('/boom-center-config-service/app/clientAndAreaConfiguration/findConfigByAdminParam', {
          params: {
            configSet: 'loginPageConfig',
            client: 'ds-h5',
            areaId: -1
          }
        })
        .then(res => {
          if (res.content) {
            this.configValue = {
              ...this.loginPageConfig,
              ...res.content
            }
          }
        })
    },
    getWxConfig() {
      this.$http
        .get('boom-center-user-service/app/members/getAnonymousToken?plantformId=1')
        .then(token => {
          axios({
            method: 'post',
            url: baseURL + 'boom-center-product-service/wechat/createJsapiSignature',
            data: {
              url:
                process.env.VUE_APP_ENV === 'prod'
                  ? 'http://ds.mall.tanchi.shop/'
                  : 'http://ds.mall.tanchi0757.shop/',
              appId: 'wx4c71feaac422f7d9'
            },
            headers: {
              'Bm-Member-Token': token
            }
          }).then(res => {
            res = res.data.data
            console.log(res)
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
              appId: res.appId, // 必填，公众号的唯一标识
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonceStr, // 必填，生成签名的随机串
              signature: res.signature, // 必填，签名
              jsApiList: ['scanQRCode'], // 必填，需要使用的JS接口列表
              openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
            })
            wx.ready(() => {
              console.log('初始化完成')
              let script = document.createElement('script')
              script.type = 'text/wxtag-template'
              script.text = `<div style="width:100%;background:#e7141a;padding:10px;text-align:center;color:#fff;box-sizing: border-box;border-radius:5px">打开微信小程序</div>`
              let html = `<wx-open-launch-weapp style="width:60vw;border:none;box-sizing: border-box;" username="gh_13a6e520b7fe" path="pages/home/index/index">${script.outerHTML}</wx-open-launch-weapp>`
              document.getElementsByClassName('wxOpenTags')[0].innerHTML = html
            })
            wx.error(err => {
              console.log('初始化错误,', err)
            })
          })
        })
    },
    getCode() {
      this.send = true
      console.log('send code')
      this.$http
        .get(`boom-center-user-service/app/sms/send/${this.form.phone}?type=0`)
        .then(res => {
          if (res.code === 3000) {
            Notify({ type: 'danger', message: res.msg })
          } else {
            Notify({ type: 'success', message: res })
          }
        })
        .catch(err => {
          this.send = false
          Dialog.alert({
            message: err.msg || '服务器內部错误'
          })
        })
    },
    getInviteMember() {
      if (localStorage['inviteMember']) {
        this.inviteMember = JSON.parse(localStorage['inviteMember'])
      }
      let memberId = this.$route.query.id
      let type = 0
      if (!Boolean(memberId)) {
        memberId = this.$route.query.userId
        type = 1
      }
      if (!Boolean(memberId)) return
      this.$http
        .get(`boom-center-user-service/app/members/get_member_simple_info/${memberId}?type=${type}`)
        .then(res => {
          this.inviteMember = res
          localStorage['inviteMember'] = JSON.stringify(res)
        })
    },
    submit() {
      this.loading = true
      this.$http
        .post('boom-center-user-service/app/distributionMember/webLogin', {
          code: this.form.code,
          inviteMemberId: this.inviteId,
          phoneNumber: this.form.phone
        })
        .then(res => {
          if (res.token) {
            localStorage['inviteMember'] = ''
            if (this.inviteId != res.distributionMemberInfo.parentMemberId && this.inviteId) {
              Notify({ type: 'warning', message: '您已经是合伙人了，不能加入ta的团队' })
            }
            localStorage['token'] = res.token
            this.$store.commit('user/userInfo', res.distributionMemberInfo)
            this.$router.replace('/home')
          }
        })
        .catch(err => {
          Dialog.alert({
            message: err.msg || '服务器內部错误'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .logoContainer {
    text-align: center;
  }
}
.formCard {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  margin: 15px;
  height: fit-content;
  background: #fff;
  border-radius: 10px;
  padding-top: 40px;

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: @color-primary;
    font-size: 20px;
    width: 100%;
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
    .name {
      margin: 15px 0;
    }
  }
  .tip {
    color: #999;
    font-size: 12px;
    text-align: center;
    margin: 20px 0;
  }
  .btn {
    background: #e7141a;
    border-radius: 5px;
    border: none;
  }

  .loginFrom {
    .submit {
      width: 100%;
      height: 44px;
    }
    .confirmCode {
      width: 78%;
    }
    .getConfirmCode {
      margin-left: 2%;
      width: 18%;
    }
  }
  .van-count-down {
    display: inline-block;
    color: #ffffff !important;
  }
  .wxOpenTags {
    position: fixed;
    bottom: 30px;
    left: 0;
    width: calc(100vw - 30px);
    margin: 0 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  }
  .invite-member {
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .name {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
